import "./imgdumper-theme.scss";

import LocalNav from "./components/Nav";
import LocalFooter from "./components/Footer";
import HomePage from "./components/Home";
import { Routes, Route, Outlet, useParams } from "react-router-dom";
import UploadView from "./components/UploadView";
import LoginView from "./components/LoginView";
import RegisterView from "./components/RegisterView";
const ImgView = () => <span>Hier zou een image moeten staan</span>;
const About = () => <span>About</span>;

const Missing = () => <span>404 pagina hier</span>;

function Layout() {
    return (
        <>
            <LocalNav />
            <Outlet />
            <LocalFooter />
        </>
    );
}

function App() {
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<HomePage />} />
                <Route path="about" element={<About />} />
                <Route path="inloggen" element={<LoginView />} />
                <Route path="registreren" element={<RegisterView />} />
                <Route path=":id" element={<ValidateUploadIdFormat />} />

                {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
                <Route path="*" element={<Missing />} />
            </Route>
        </Routes>
    );
}

function ValidateUploadIdFormat() {
    let params = useParams();
    let id = params.id.match(
        /[23456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{22}/,
    );
    if (!id) {
        return <Missing />;
    }
    return <UploadView uploadId={params.id} />;
}

export default App;
