import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import {Row} from "react-bootstrap";
import {getUpload} from "../lib/uploads";
import config from "../config";
import React, {useState} from "react";
import {EmojiFrown} from "react-bootstrap-icons";
import {Image} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {Link} from "react-router-dom";

class RegisterView extends React.Component {
    render() {
        return (
            <Container className="py-5">
                <Row className="d-flex justify-content-center">
                    <Col md="4">
                        <h3 className="mb-3 fw-normal text-center">Registreren</h3>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicName">
                                <Form.Label>Naam</Form.Label>
                                <Form.Control type="text" placeholder="Voer je naam in"/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>E-mailadres</Form.Label>
                                <Form.Control type="email" placeholder="Voer je e-mailadres in"/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Wachtwoord</Form.Label>
                                <Form.Control type="password" placeholder="Voer je wachtwoord in"/>
                            </Form.Group>

                            <Button variant="success" type="submit">
                                Registreren
                            </Button>


                        </Form>
                    </Col>
                </Row>
            </Container>
        )
            ;
    }
}


export default RegisterView;
