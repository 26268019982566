import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import logo from "../resources/logo.png";
import Badge from "react-bootstrap/Badge";

function LocalNav() {
    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <Col>
                    <LinkContainer to="/">
                        <Navbar.Brand>
                            <img src={logo} alt="IMGDumper Logo" />{" "}
                            <Badge bg="danger">BETA</Badge>
                        </Navbar.Brand>
                    </LinkContainer>
                </Col>
                <Col>
                    <Nav className="justify-content-center">
                        <Nav.Item>
                            <LinkContainer to="/">
                                <Nav.Link>Home</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/help">
                                <Nav.Link>Help</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/contact">
                                <Nav.Link>Contact</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/over-imgdumper">
                                <Nav.Link>Waarom IMGDumper?</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col>
                    <Navbar.Collapse className="justify-content-end">
                        <Nav>
                            <Nav.Item>
                                <LinkContainer to="/inloggen">
                                    <Button
                                        className="me-2"
                                        variant="outline-success"
                                    >
                                        Inloggen
                                    </Button>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/registreren">
                                    <Button variant="success">
                                        Registreren
                                    </Button>
                                </LinkContainer>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Col>
            </Container>
        </Navbar>
    );
}

export default LocalNav;
