import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { Row } from "react-bootstrap";
import { getUpload } from "../lib/uploads";
import config from "../config";
import React, { useState } from "react";
import { EmojiFrown } from "react-bootstrap-icons";
import { Image } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

class UploadView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            upload: null,
            showToast: false,
        };
    }

    componentDidMount() {
        getUpload(this.props.uploadId).then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    upload: result.data,
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    isLoaded: true,
                    error: error,
                });
            },
        );
    }

    handleImageClick = (e) => {
        const url = e.target.src;
        // Write URL to clipboard
        navigator.clipboard.writeText(url);
        this.setState({ showToast: true });
        e.preventDefault();
    };

    render() {
        const { error, isLoaded, upload } = this.state;
        if (error) {
            if (error.response && error.response.status === 404) {
                return <UploadNotFound />;
            }
            // FIXME some other problem
        } else if (!isLoaded) {
            return (
                <Container className="py-5">
                    <Row>
                        <Col className="text-center text-muted">
                            <Spinner
                                size={96}
                                animation="border"
                                variant="success"
                            />
                        </Col>
                    </Row>
                </Container>
            );
        } else {
            return (
                <Container className="py-5">
                    <Row>
                        <Col className="text-center text-muted">
                            <h1 className="display-4">{upload.file_name}</h1>
                            <Image
                                alt={upload.file_name}
                                onClick={this.handleImageClick}
                                className="m-5 shadow rounded"
                                fluid={true}
                                src={`/${upload.upload_id}.${upload.type}`}
                            />
                            <h1 className="fs-6">
                                <strong>Tip!</strong> klik op de afbeelding om
                                het pad te kopieren
                            </h1>
                        </Col>
                    </Row>
                    <ToastContainer position="bottom-center" className="p-3">
                        <Toast
                            onClose={() => this.setState({ showToast: false })}
                            show={this.state.showToast}
                            delay={3000}
                            autohide
                        >
                            <Toast.Header closeButton={false}>
                                <strong className="me-auto">
                                    URL gekopieerd naar klembord
                                </strong>
                            </Toast.Header>
                        </Toast>
                    </ToastContainer>
                </Container>
            );
        }
    }
}

function UploadNotFound() {
    return (
        <Container className="py-5">
            <Row>
                <Col className="text-center text-muted">
                    <EmojiFrown size={96} />
                    <h1 className="display-2 py-4">Afbeelding niet gevonden</h1>
                </Col>
            </Row>
        </Container>
    );
}

export default UploadView;
