
const base_path = process.env.REACT_APP_BASE_URL || "http://localhost:8001"

const config = {
    version: process.env.REACT_APP_VERSION || "unknown",
    basePath:  base_path,
    apiBasePath: base_path + "/api",
};

export default config;
