import IMGDumperClient from "../client";

export function getUpload(uploadId) {
    return IMGDumperClient.get(`/upload/${uploadId}`);
}

export function uploadImages(files) {
    const formData = new FormData();
    for (const file of files) {
        formData.append("files", file);
    }
    return IMGDumperClient.post("/upload", formData);
}
