import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { Row } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Upload } from "react-bootstrap-icons";
import { Form } from "react-bootstrap";
import React, { useState } from "react";
import { uploadImages } from "../lib/uploads";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

function dragOverHandler(ev) {
    ev.preventDefault();
}

function HomePage() {
    const [redirect, setRedirect] = useState(false);
    const [uploading, setUploading] = useState(false);
    const uploadRef = React.useRef(null);
    const onUploadColClick = () => {
        uploadRef.current.click();
    };
    const navigate = useNavigate();

    // File paste
    // const onFilePaste = (e) => {
    //     e.preventDefault();
    //
    //     console.log(e.clipboardData)
    //
    //     return
    //     let files = [];
    //     if (e.clipboardData && e.clipboardData.items) {
    //         for (const file of e.clipboardData.items) {
    //             console.log(file)
    //             files.push(file.getAsFile())
    //         }
    //         console.log(files)
    //         setUploading(true);
    //         uploadImages(files).then((result) => {
    //             setRedirect(result.data.success[0].upload_id);
    //         });
    //     }
    // }

    // File drop
    const onFileDrop = (e) => {
        e.preventDefault();

        // FIXME: Missing file type checks.

        let files = [];
        if (e.dataTransfer.items) {
            for (const file of e.dataTransfer.items) {
                files.push(file.getAsFile());
            }
        } else {
            for (const file of e.dataTransfer.files) {
                files.push(file.getAsFile());
            }
        }
        setUploading(true);
        uploadImages(files).then((result) => {
            setRedirect(result.data.success[0].upload_id);
        });
    };

    // File select
    const onFileSelect = (e) => {
        uploadRef.current.disabled = true;
        setUploading(true);
        const files = uploadRef.current.files;
        uploadImages(files).then((result) => {
            setRedirect(result.data.success[0].upload_id);
        });
        e.preventDefault();
    };

    React.useEffect(() => {
        if (redirect) {
            navigate(`/${redirect}`);
        }
    });

    return (
        <>
            <Container className="px-4 py-5">
                <Row className="align-items-center g-lg-5 py-5">
                    <Col lg={6} className="text-center text-lg-start">
                        <h1 className="display-1">
                            Deel al je afbeeldingen met de wereld
                        </h1>
                    </Col>
                    <Col
                        md={10}
                        lg={6}
                        onDrop={onFileDrop}
                        onDragOver={dragOverHandler}
                        //onPaste={onFilePaste}
                    >
                        <Card
                            onClick={onUploadColClick}
                            className="text-muted text-center rounded-pill"
                        >
                            <Card.Body className="py-5">
                                <Card.Text>
                                    {!uploading && <Upload size={96} />}
                                    {uploading && (
                                        <Spinner
                                            size={96}
                                            animation="border"
                                            variant="success"
                                        />
                                    )}
                                    <br />
                                    <span>
                                        Zet je afbeelding hier neer of klik om
                                        afbeelding te selecteren
                                    </span>
                                    <Form.Control
                                        onChange={onFileSelect}
                                        ref={uploadRef}
                                        type="file"
                                        hidden
                                        multiple
                                        accept="image/*"
                                    />
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default HomePage;
