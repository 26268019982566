import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import { LinkContainer } from "react-router-bootstrap";
import config from "../config";
function LocalFooter() {
    return (
        <Navbar fixed="bottom" bg="light">
            <Container>
                <Col>
                    IMGDumper.nl <Badge bg="success">sinds 2007</Badge>
                </Col>
                <Col className="text-center">
                    <small className="text-danger">
                        Publieke beta versie ({config.version})
                    </small>
                </Col>
                <Col>
                    <Nav className="justify-content-end">
                        <Nav.Item>
                            <LinkContainer to="/misbruik">
                                <Nav.Link>Misbruik rapporteren</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Container>
        </Navbar>
    );
}

export default LocalFooter;
